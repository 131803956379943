/* eslint-disable */
import React, { Component } from 'react';
import ChatScreen from './component.jsx';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import { getDatabaseList, getEmbeddedDatabaseList } from '@reducers/database';
import {
  getItemDetails,
  getItemDetailsByQuery,
  getAnnotations,
  deleteItemFromFilterList,
} from '@reducers/query';
import {
  getChatModelList,
  getAllPerspectivesForOrg,
  getPerspectivesForDatabase,
  createPerspective,
} from '@reducers/chat';
import { getAllTags, getQueryResult } from '@reducers/sqlEditor';
import { getAddedFilterApiData } from '@reducers/tags';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
const InitialConfig = AntdConfig;
import AlertDialogDelete from '../Filter/deleteAlertDialog.jsx';
import AlertDialogEdit from './editAlert.jsx';
import { APIConfig } from '@constants';
import { Grid, Typography } from '@material-ui/core';
import api from '../../constants/api.js';
import { Context } from '../AppProvider/AppProvider.jsx';
import { Snackbar } from '@mui/material';

export class ChatWindow extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = {
      databaseList: [],
      databaseName: '',
      databaseNameMap: {},
      dbId: '',
      schemaId: '',
      messagesLoading: false,
      loading: true,
      messages: {},
      hasMoreMessages: false,
      chatString: '',
      openAIApiKey: '',
      kValue: 8,
      limit: '',
      synth: window.speechSynthesis,
      voices: [],
      voice: null,
      chatPrompt: '',
      chatModels: [],
      chatPerspectives: [],
      selectedModel: null,
      selectedPerspective: null,
      snackbarOpen: false,
      snackbarMessage: '',
      metadata: null,
    };
  }

  getEmbeddedDatabases() {
    const apiKey = window.localStorage.getItem('apiKey');
    this.props.getEmbeddedDatabaseList(apiKey).then(data => {
      if (!(data && data.responseList && data.responseList.length)) {
        return;
      }
      const embeddedDatabases = {};
      data.responseList.forEach(db => {
        embeddedDatabases[db.id] = {
          lastEmbeddedOn: db.embeddedOn ? ~~(db.embeddedOn / 1000000000) : null,
        };
      });
      this.setState({ embeddedDatabases: embeddedDatabases });
    });
  }

  componentDidMount() {
    const userId = window.localStorage.getItem('userSurrogateId');
    const apiKey = window.localStorage.getItem('apiKey');
    let dbId = this.state.dbId;

    const searchParams = new URLSearchParams(this.props.location.search);
    const database = searchParams.get('database');

    this.props.getDatabaseList(userId, 0, 0).then(data => {
      const databaseNameMap = {};
      let databaseList = [];
      let defaultSelectedDatabase = '';
      const messages = {};

      if (data.responseList && data.responseList.length) {
        data.responseList.forEach(db => {
          databaseNameMap[db.name] = db.id;
          messages[db.name] = [];
        });
        databaseList = data.responseList;
        defaultSelectedDatabase =
          data.responseList[0] && data.responseList[0].name;
      }
      const voice = this.state.synth.getVoices().find(v => v.lang == 'en-US');
      const newState = {
        messages: messages,
        databaseList: databaseList,
        databaseName: defaultSelectedDatabase,
        databaseNameMap: databaseNameMap,
        voices: this.state.synth.getVoices(),
        voice: voice,
        open: false,
      };
      if (!dbId) {
        dbId = databaseNameMap[defaultSelectedDatabase];
        newState['dbId'] = dbId;
        this.getPerspectivesForDatabase(apiKey, dbId);
        if (dbId) {
          this.getDatabaseMetadata(apiKey, dbId).then(async data => {
            const status = data.status;
            const res = await data.json();
            if (status == 200) {
              console.log('metadataResponse :', res.metadata);
              this.setState({
                metadata: res.metadata,
              });
            }
          });
        }
      }

      //this.setPreviousMessages(defaultSelectedDatabase)

      if (database) {
        newState['databaseName'] = database;
      }
      this.setState(newState);
    });
    this.getEmbeddedDatabases();
    this.getChatModelList(apiKey);
    this.getPerspectivesForDatabase(apiKey, dbId);
  }

  componentDidUpdate(prevProps, prevState) {
    const [contextState, dispatch] = this.context;
    console.log('this.state.databaseName', this.state.databaseName);
    if (prevState.databaseName !== contextState.globalDatabaseName) {
      console.log(
        'prevState.databaseName: ',
        prevState.databaseName,
        'contextState.globalDatabaseName: ',
        contextState.globalDatabaseName
      );
      this.handleChange('databaseName')({
        target: { value: contextState.globalDatabaseName },
      });
    }
    if (
      this.state.databaseName &&
      this.state.databaseName !== prevState.databaseName &&
      this.state.databaseName === contextState.globalDatabaseName
    ) {
      this.setPreviousMessages(this.state.databaseName);
      const apiKey = window.localStorage.getItem('apiKey');
      const dbId = this.state.dbId;
      if (dbId) {
        this.getDatabaseMetadata(apiKey, dbId).then(async data => {
          const status = data.status;
          const res = await data.json();
          if (status == 200) {
            console.log('metadataResponse :', res.metadata);
            this.setState({
              metadata: res.metadata,
            });
          }
        });
      }
      this.setState({
        selectedPerspective: null,
        selectedModel: this.state?.chatModels?.[0]?.id || '',
        chatPrompt: '',
      });
      this.getPerspectivesForDatabase(apiKey, dbId);
    }
  }
  getChatModelList = apiKey => {
    try {
      this.props.getChatModelList(apiKey).then(chatModelList => {
        this.setState({
          chatModels: chatModelList,
          selectedModel: chatModelList?.[0]?.id,
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  getAllPerspectivesForOrg = apiKey => {
    try {
      this.props.getAllPerspectivesForOrg(apiKey).then(perspectives => {
        const chatPerspectives = perspectives && perspectives.responseList;
        console.log('perspectives: ', chatPerspectives);
        this.setState({ chatPerspectives: chatPerspectives || [] });
      });
    } catch (error) {
      console.error(error);
    }
  };

  getPerspectivesForDatabase = (apiKey, dbId) => {
    if (!dbId) {
      return;
    }
    try {
      this.props.getPerspectivesForDatabase(apiKey, dbId).then(perspectives => {
        const chatPerspectives = perspectives && perspectives.responseList;
        console.log('perspectives: ', chatPerspectives);
        this.setState({ chatPerspectives: chatPerspectives || [] });
      });
    } catch (error) {
      console.error(error);
    }
  };

  handlePerspectiveSelection = selectedPerspective => {
    if (
      !selectedPerspective ||
      selectedPerspective?.id === this.state?.selectedPerspective?.id
    ) {
      this.setState({
        selectedPerspective: null,
        selectedModel: this.state?.chatModels?.[0]?.id || '',
        chatPrompt: '',
      });
      return;
    }
    this.setState({
      selectedPerspective: selectedPerspective,
      selectedModel:
        selectedPerspective?.chatModelName ||
        this.state?.chatModels?.[0]?.id ||
        '',
      chatPrompt: selectedPerspective?.prompt || '',
    });
    if (!selectedPerspective) {
      return;
    }
    const messageObj = {
      from: 'Bot',
      message: 'You are logged in as ' + selectedPerspective?.name,
    };
    this.onSendChat(messageObj);
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
    this.state.databaseList &&
      this.state.databaseList.map(db => {
        if (db.name === event.target.value) {
          this.setState({ dbId: db.id, schemaId: db.schemaId });
        }
      });
    this.doReset();
  };

  handleVoiceChange = event => {
    console.log(event.target.value);
    this.setState({ voice: event.target.value });
  };

  handleOpenAIApiKeyChange = event => {
    console.log('new API key value : ', event.target.value);
    this.setState({ openAIApiKey: event.target.value });
  };

  handleKValueChange = event => {
    this.setState({ kValue: event.target.value });
  };

  handleLimitChange = event => {
    console.log('limit: ', event.target.value);
    this.setState({ limit: event.target.value });
  };

  handleChatModelChange = chatModel => {
    console.log('selectedModel: ', chatModel || '');
    this.setState({ selectedModel: chatModel || '' });
  };

  handleChatPerspectiveChange = perspective => {
    console.log('perspective: ', perspective || '');
    this.setState({ selectedPerspective: perspective || '' });
  };

  handleChatPromptChange = chatPrompt => {
    console.log('chatPrompt: ', chatPrompt || '');
    this.setState({ chatPrompt: chatPrompt || '' });
  };

  getItemDetails = (payload, calledFrom) => {
    const orgName = window.localStorage.getItem('orgName');
    this.setState({ loading: true });
    const callPayload = payload
      ? payload
      : {
          databaseId: this.state.dbId,
          pageNo: this.state.pageNo,
          pageSize: this.state.pageSize,
        };
    this.props
      .getItemDetails(callPayload)
      .then(data => {
        this.setProductData(data, calledFrom);
      })
      .catch(err => {
        console.log('error', err);
        this.setState({ loading: false });
      });
  };

  /* static getDerivedStateFromProps(props, state) {
        if (props.databaseList.responseList && (props.databaseList.responseList !== this.state.databaseList)) {
            return props.databaseList.responseList && props.databaseList.responseList.length ? {
                databaseList: props.databaseList.responseList,
                databaseName: props.databaseList.responseList[0].name,
                dbId: props.databaseList.responseList[0].id,
                schemaId: props.databaseList.responseList[0].schemaId,
            } : {}
        }
        return null
    } */

  doReset = key => {
    this.setState({}, () => {});
    if (key) {
    }
  };

  setPreviousMessages = databaseName => {
    const apiKey = window.localStorage.getItem('apiKey');
    this.getChatMessagesDB(apiKey, databaseName).then(async data => {
      console.log('data :', data);
      const status = data.status;
      const res = await data.json();
      console.log('res :', res);
      if (status == 200) {
        this.clearChatMessageHistory();
        console.log(res);
        const messages = [];
        res.items.forEach(r => {
          let messageObj = {
            from: r.BerryDb.roleCategory == 'User' ? 'User' : 'Bot',
            message: r.BerryDb.message,
          };
          this.onSendChat(messageObj);
        });
        // console.log(messages)
        // this.setState({ messages: messages });
      }
    });
  };

  onChange = event => {
    this.setState({ chatString: event.target.value });
  };

  onChatString = chat => {
    this.setState({ chatString: chat });
  };

  onSendChat = messageObj => {
    if (!messageObj) {
      return;
    }
    const newMessages = this.state.messages;
    (newMessages[this.state.databaseName] || []).push(messageObj);
    this.setState({ chatString: '', messages: newMessages });
  };

  clearChatMessageHistory = () => {
    const newMessages = this.state.messages;
    newMessages[this.state.databaseName] = [];
    this.setState({ messages: newMessages });
  };

  async chatEmbed(apiKey, databaseName, openAIApiKey, k, limit) {
    const userId = window.localStorage.getItem('userSurrogateId');
    const orgName = window.localStorage.getItem('orgName');
    const authUrl = `${api.chatHostname}/${api.endpoints.chatEmbed}`;
    const body = {
      apiKey: apiKey,
      database: databaseName,
      openAIApiKey: openAIApiKey,
      k: k,
      limit: limit,
      userId: userId + '',
      orgName: orgName,
      schemaName: 'ChatMessagesSchema',
      createDatabase: true,
    };
    const urlParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    try {
      return fetch(authUrl, urlParams);
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  }

  async getChatMessagesDB(apiKey, databaseName) {
    const userId = window.localStorage.getItem('userSurrogateId');
    const orgName = window.localStorage.getItem('orgName');
    const authUrl = `${api.chatHostname}/${api.endpoints.chatMessages}`;
    console.log('databaseName', databaseName);
    const body = {
      database: databaseName,
      apiKey: apiKey,
      userId: userId + '',
      orgName: orgName,
      schemaName: 'ChatMessagesSchema',
    };
    const urlParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    try {
      const response = fetch(authUrl, urlParams);
      return response;
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  }
  async getDatabaseMetadata(apiKey, databaseId) {
    const userId = window.localStorage.getItem('userSurrogateId');
    const orgName = window.localStorage.getItem('orgName');
    const authUrl = `${api.hostname}/berrydb/database/${databaseId}/metadata`;
    const urlParams = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = fetch(authUrl, urlParams);
      return response;
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  }

  async askChatQuestion(
    question,
    k,
    database,
    apiKey,
    openAIApiKey,
    selectedModel,
    chatPrompt,
    temperature
  ) {
    // const chatSessionId = window.localStorage.getItem('chatSessionId');
    /* if (!chatSessionId) {
            return;
        } */
    const userId = window.localStorage.getItem('userSurrogateId');
    const orgName = window.localStorage.getItem('orgName');
    const authUrl = `${api.chatHostname}/${api.endpoints.chatQuestion}/`;
    const body = {
      question: question,
      // sessionId: chatSessionId,
      k: k,
      database: database,
      apiKey: apiKey,
      openAIApiKey: openAIApiKey,
      saveToDatabase: true,
      userId: userId + '',
      orgName: orgName,
      model: selectedModel,
      systemPrompt: chatPrompt,
      temperature: temperature,
    };
    const urlParams = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    try {
      return fetch(authUrl, urlParams);
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  }

  handleCreatePerspective = perspective => {
    const apiKey = window.localStorage.getItem('apiKey');
    const dbId = this.state.dbId;
    console.log('perspective', perspective);
    if (!(dbId && perspective)) {
      return;
    }
    perspective['databaseId'] = dbId;
    try {
      this.props
        .createPerspective(apiKey, perspective)
        .then(data => {
          console.log(data);
          this.getPerspectivesForDatabase(apiKey, dbId);
        })
        .catch(err => {
          console.error(err);
          let errorMessage;
          try {
            errorMessage = err?.message;
            errorMessage = JSON.parse(errorMessage);
            errorMessage = errorMessage?.errorMessage;
          } catch (e) {
            errorMessage = null;
            console.error(e);
          }
          this.setSnackbarOpen(
            true,
            errorMessage || 'Oops! Something went wrong, try again later'
          );
        })
        .finally(() => {});
    } catch (e) {
      console.error(e);
    }
  };

  setSnackbarOpen = (setToOpen = false, snackbarMessage = '') => {
    this.setState({
      snackbarMessage: snackbarMessage,
      snackbarOpen: setToOpen,
    });
  };

  render() {
    if (!(this.state.databaseList && this.state.databaseList.length)) {
      return (
        <Grid container direction="row" className="p-3r">
          <Grid item xs={12} className="my-10">
            <Typography variant="subtitle">
              <b>No Database found. Create a Database to Query.</b>
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <div className="overflow-hidden">
          <ChatScreen
            databaseList={this.state.databaseList}
            databaseValue={this.state.databaseName}
            databaseId={this.state.dbId}
            databaseNameMap={this.state.databaseNameMap}
            messages={this.state.messages[this.state.databaseName] || []}
            handleChange={this.handleChange}
            paginationLoading={this.state.paginationLoading}
            onReset={this.doReset}
            chatString={this.state.chatString}
            onChange={this.onChange}
            onChatString={this.onChatString}
            onSendChat={this.onSendChat}
            openAIApiKey={this.state.openAIApiKey}
            kValue={this.state.kValue}
            limit={this.state.limit}
            handleOpenAIApiKeyChange={this.handleOpenAIApiKeyChange}
            handleKValueChange={this.handleKValueChange}
            handleLimitChange={this.handleLimitChange}
            chatEmbed={this.chatEmbed}
            // deleteChatSession={this.deleteChatSession}
            askChatQuestion={this.askChatQuestion}
            clearChatMessageHistory={this.clearChatMessageHistory}
            embeddedDatabases={this.state.embeddedDatabases}
            getEmbeddedDatabases={() => this.getEmbeddedDatabases()}
            getChatMessagesDB={this.getChatMessagesDB}
            getDatabaseMetadata={this.getDatabaseMetadata}
            synth={this.state.synth}
            voices={this.state.voices}
            voice={this.state.voice}
            handleVoiceChange={this.handleVoiceChange}
            chatPrompt={this.state.chatPrompt}
            handleChatPromptChange={this.handleChatPromptChange}
            chatModels={this.state.chatModels}
            chatPerspectives={this.state.chatPerspectives}
            handleChatModelChange={this.handleChatModelChange}
            handleChatPerspectiveChange={this.handleChatPerspectiveChange}
            selectedModel={this.state.selectedModel}
            handlePerspectiveSelection={this.handlePerspectiveSelection}
            createPerspective={this.props.createPerspective}
            getAllPerspectivesForOrg={this.getAllPerspectivesForOrg}
            getPerspectivesForDatabase={this.getPerspectivesForDatabase}
            selectedPerspective={this.state.selectedPerspective}
            handleCreatePerspective={this.handleCreatePerspective}
            metadata={this.state.metadata}
          />
          <AlertDialogDelete
            showAlertForDelete={this.state.showAlertForDelete}
            setOpen={() =>
              this.setState({
                showAlertForDelete: !this.state.showAlertForDelete,
              })
            }
            onDeleteItem={this.onDeleteItem}
          />
          <AlertDialogEdit
            showAlertForEdit={this.state.showAlertForEdit}
            setOpen={() =>
              this.setState({ showAlertForEdit: !this.state.showAlertForEdit })
            }
            onConfirmEdit={this.onConfirmEdit}
            enableQueryBuilder={this.state.enableQueryBuilder}
          />
        </div>
        <Snackbar
          message={this.state.snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={5000}
          onClose={() => this.setSnackbarOpen(false)}
          open={this.state.snackbarOpen}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  databaseList: state.database.databaseList,
  itemOfFields: state.sqlEditor.items,
  loading: state.query.loading,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDatabaseList,
      getItemDetails,
      getItemDetailsByQuery,
      getAnnotations,
      deleteItemFromFilterList,
      getAllTags,
      getAddedFilterApiData,
      getQueryResult,
      getEmbeddedDatabaseList,
      getChatModelList,
      getAllPerspectivesForOrg,
      getPerspectivesForDatabase,
      createPerspective,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatWindow)
);
