/* eslint-disable */
import { CUSTOM_MESSAGES } from '@constants';
import { Button, Dialog, DialogContent, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import BackupIcon from '@mui/icons-material/Backup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import logMessages from '../../constants/logMessages';
import {
  createBackup,
  getBackupList,
  restoreBackup,
} from '../../reducers/backupRestore';
import { getAnnotationsAccessToken, getUserDetails } from '../../reducers/otp';
import CopyToClipboardButton from '../CopyToClipboardButton';
import styles from './index.module.scss';

const Notepad = props => {
  const posthog = usePostHog();
  const [apiKey, setApiKey] = useState('Fetching...');
  const [annotationsAccessToken, setAnnotaionsAccessToken] = useState(
    'Fetching...'
  );
  const [isBackupRestoreModalOpen, setIsBackupRestoreModalOpen] = useState(
    false
  );
  const [isBackupInputModalOpen, setIsBackupInputModalOpen] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [overlayMessages, setOverlayMessages] = useState(null);
  const [overlayMessageIndex, setOverlayMessageIndex] = useState(0);

  const [backupName, setBackupName] = useState('');
  const [backupInputDirty, setBackupInputDirty] = useState('');
  const [backupList, setBackupList] = useState([]);
  const [fetchingBackupList, setFetchingBackupList] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isInfoModalError, setIsInfoModalError] = useState(false);
  const [infoModalMessage, setInfoModalMessage] = useState('');

  const orgName = window.localStorage.getItem('orgName');

  const isBackupNameInvalid = useMemo(() => !(backupName || '').trim(), [
    backupName,
  ]);

  const backupMessages = [
    'Please Wait...',
    'We are backing up your data...',
    'This may take a few minutes...',
    'Did you know? With BerryDB you can annotate your data.',
  ];

  const restoreMessages = [
    'Please Wait...',
    'Reviving your files from slumber...',
    'We are restoring your data...',
    'This may take a few minutes...',
    'Did you know? With BerryDB you can annotate your data.',
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const userEmail = window.localStorage.getItem('userEmail');
    props
      .getUserDetails(userEmail)
      .then(userInfo => {
        setApiKey(userInfo.userDetails.apiKey);
      })
      .catch(err => {
        console.error(err);
        setApiKey('Oops! Something went wrong, please refresh the page.');
      });
  }, []);

  useEffect(() => {
    if (apiKey) {
      props
        .getAnnotationsAccessToken(apiKey)
        .then(data => {
          setAnnotaionsAccessToken(data.accessToken);
        })
        .catch(err => {
          console.error(err);
          setAnnotaionsAccessToken(
            'Oops! Something went wrong, please refresh the page.'
          );
        });
    }
  }, [apiKey]);

  useEffect(() => {
    if (isBackupRestoreModalOpen) {
      getAllBackups();
    }
  }, [isBackupRestoreModalOpen]);

  const getAllBackups = async () => {
    const userSurrogateId = window.localStorage.getItem('userSurrogateId');
    try {
      setFetchingBackupList(true);
      const res = await props.getBackupList(userSurrogateId);
      let backups = [];
      if (res && res.backups && res.backups.length) {
        backups = res.backups;
      }
      setBackupList(backups);
    } catch (err) {
      console.error(err);
      setBackupList([]);
    } finally {
      setFetchingBackupList(false);
    }
  };

  const createBackup = async backupName => {
    const userSurrogateId = window.localStorage.getItem('userSurrogateId');
    const payload = {
      name: backupName,
      createdBy: userSurrogateId,
    };
    try {
      setIsBackupInputModalOpen(false);
      setShowOverlay(true);
      setOverlayMessages(backupMessages);
      const res = await props.createBackup(payload);
      if (res && res.backup) {
        showInfoModal(false, 'Backup created successfully');
        getAllBackups();
      }
    } catch (err) {
      showInfoModal(true, 'Could not create a backup. Please try again later');
      console.error(err);
    } finally {
      setShowOverlay(false);
      setOverlayMessageIndex(0);
      setBackupName(null);
      setBackupInputDirty(false);
    }
  };

  const restoreBackup = async backupId => {
    try {
      setShowOverlay(true);
      setOverlayMessages(restoreMessages);
      const res = await props.restoreBackup(backupId);
      if (res && res.success) {
        showInfoModal(false, 'Backup restored successfully');
      }
    } catch (err) {
      showInfoModal(
        true,
        'Could not restore the backup. Please try again later'
      );
      console.error(err);
    } finally {
      setShowOverlay(false);
      setOverlayMessageIndex(0);
    }
  };

  const showInfoModal = (error, message) => {
    setIsInfoModalOpen(true);
    setIsInfoModalError(error);
    setInfoModalMessage(
      message || (error ? 'Oops! Something went wrong' : 'Success!!!')
    );
  };

  const renderInfoModal = () => {
    return (
      <Dialog open={isInfoModalOpen} className={styles['info-msg-dialog']}>
        <DialogContent>
          <div className={styles.title}>
            {isInfoModalError ? 'Error!' : 'Message'}
          </div>
          <div className={styles.msg}>
            {isInfoModalError ? (
              <ErrorOutlineIcon
                className={[styles.icon, styles['icon-red']].join(' ')}
              />
            ) : (
              <CheckCircleOutlineIcon
                className={[styles.icon, styles['icon-green']].join(' ')}
              />
            )}
            {infoModalMessage}
          </div>
          <Button
            onClick={() => {
              setIsInfoModalOpen(false);
            }}
            className={styles.btn}
            variant="contained"
            color="inherit"
            style={{
              backgroundColor: '#fff',
              border: '1px solid #3F51B5',
              color: '#3F51B5',
              fontSize: '12px',
            }}
          >
            Ok
          </Button>
        </DialogContent>
      </Dialog>
    );
  };

  useEffect(() => {
    let timeoutRef = null;
    if (!showOverlay) {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
      return;
    }
    timeoutRef = setTimeout(() => {
      setOverlayMessageIndex(
        messageCount => (messageCount + 1) % (overlayMessages || []).length
      );
    }, 5000);
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [showOverlay, overlayMessageIndex]);

  const renderOverlay = () => {
    if (!showOverlay) {
      return <></>;
    }
    return (
      <div
        className={styles.overlay}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className={styles.text}>
          {overlayMessages && overlayMessages.length
            ? overlayMessages[overlayMessageIndex]
            : 'Processing...'}
        </div>
      </div>
    );
  };

  const handleCreateBackup = () => {
    const name = (backupName || '').trim();
    if (isBackupNameInvalid) {
      return;
    }
    createBackup(name);
  };

  const getDefaultBackupName = () => {
    const now = moment();
    const formattedDateTime = now.format('DD-MM-YYYY_HH:mm');
    const dateString = `backup_${formattedDateTime}`;
    return dateString;
  };

  const getDateTimeFromTimestamp = timestamp => {
    const momentObj = moment(timestamp);
    const formattedTime = momentObj.format('DD MMM YY HH:mm:ss');
    return formattedTime;
  };

  const renderBackupInputModal = () => {
    if (isBackupInputModalOpen && !backupInputDirty && isBackupNameInvalid) {
      setBackupName(() => getDefaultBackupName());
    }
    return (
      <Dialog
        open={isBackupInputModalOpen}
        // onClose={() => setIsBackupInputModalOpen(false)}
        className={styles['backup-input-dialog']}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <TextField
            variant="standard"
            className={['Underline', styles.input].join(' ')}
            name="Test"
            label="Backup Name"
            floatingLabelText={'Backup Name'}
            value={backupName}
            onChange={e => {
              setBackupInputDirty(true);
              setBackupName(e.target.value);
            }}
          />
          {isBackupNameInvalid && (
            <div className={styles['error-msg']}>
              Backup name cannot be empty
            </div>
          )}
          <div className={styles['btn-row']}>
            <Button
              disabled={isBackupNameInvalid}
              onClick={() => handleCreateBackup()}
              className={styles.btn}
              variant="contained"
              color="primary"
            >
              <div className={styles['btn-label']}>
                <BackupIcon className={styles.icon} />
                Create Backup
              </div>
            </Button>
            <Button
              onClick={() => {
                setIsBackupInputModalOpen(false);
                setBackupName(null);
                setBackupInputDirty(false);
              }}
              className={styles.btn}
              variant="contained"
              color="inherit"
              style={{
                backgroundColor: '#fff',
                border: '1px solid #E53935',
                color: '#E53935',
                fontSize: '12px',
              }}
            >
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const renderBackupRestoreModal = () => {
    return (
      <Dialog
        open={isBackupRestoreModalOpen}
        // onClose={() => setIsBackupRestoreModalOpen(false)}
        className={styles['backup-dialog']}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={styles.title} id="alert-dialog-title">
          Backup and Restore
        </div>
        <DialogContent>
          <div className={styles.warning}>
            <div>
              <WarningAmberIcon className={styles.icon} />
              <b className={styles.bold}>Warning: </b>
              Please do not use or refresh the page while the backup/restore is
              in progress. The process may take a few minutes.
            </div>
            <Button
              onClick={() => setIsBackupInputModalOpen(true)}
              className={styles.btn}
              variant="contained"
              color="primary"
            >
              <div className={styles.label}>
                <BackupIcon className={styles.icon} />
                Backup Now
              </div>
            </Button>
          </div>
          <div className={styles['table-title']}>Backups</div>
          {fetchingBackupList ? (
            <div className={styles['info-msg']}>Fetching Backup List...</div>
          ) : !(backupList && backupList.length) ? (
            <div className={styles['info-msg']}>No backups found</div>
          ) : (
            <div className={styles['backup-table']}>
              <div className={styles.row}>
                <div
                  className={[
                    styles.item,
                    styles['item-bold'],
                    styles['item-center'],
                  ].join(' ')}
                >
                  Name
                </div>
                <div
                  className={[
                    styles.item,
                    styles['item-bold'],
                    styles['item-center'],
                  ].join(' ')}
                >
                  Date & Time
                </div>
                <div
                  className={[
                    styles.item,
                    styles['item-bold'],
                    styles['item-center'],
                  ].join(' ')}
                >
                  Actions
                </div>
              </div>
              {backupList.map(backup => (
                <div className={styles.row} key={backup.id}>
                  <div className={styles.item}>{backup.name}</div>
                  <div className={styles.item}>
                    {backup.createdOn
                      ? getDateTimeFromTimestamp(backup.createdOn)
                      : ''}
                  </div>
                  <div className={styles.item}>
                    <div className={styles['item-center']}>
                      <Button
                        disabled={!backup.id}
                        onClick={() => restoreBackup(backup.id)}
                        className={styles['restore-btn']}
                        variant="contained"
                        color="primary"
                      >
                        <div className={styles.label}>
                          <RestoreIcon className={styles.icon} /> Restore
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className={styles['centered-btn']}>
            <Button
              onClick={() => setIsBackupRestoreModalOpen(false)}
              className={styles.btn}
              variant="contained"
              color="inherit"
              style={{
                backgroundColor: '#fff',
                border: '1px solid #E53935',
                color: '#E53935',
                fontSize: '12px',
              }}
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      <div className="mt-5 d-flex justify-content-center reduced_list_gap">
        <Card style={{ width: '90%', padding: '30px 15px', marginTop: '20px' }}>
          <CardContent>
            <div className={styles.header}>
              <Typography variant="title" style={{ fontSize: '21px' }}>
                {CUSTOM_MESSAGES.SETTINGS_HEADING}
              </Typography>
              <Button
                onClick={() => setIsBackupRestoreModalOpen(true)}
                className=""
                variant="contained"
                color="primary"
              >
                Backup & restore
              </Button>
            </div>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>1</ListItemIcon>
                <ListItemText>
                  <Typography
                    onClick={e => {
                      posthog?.capture(logMessages.COPIED_API_KEY_TO_CLIPBOARD);
                    }}
                  >
                    {`API Key: ${apiKey}`}
                    <CopyToClipboardButton text={apiKey} />
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>2</ListItemIcon>
                <ListItemText>
                  <Typography
                    onClick={e => {
                      posthog?.capture(
                        logMessages.COPIED_ACCESS_TOKEN_TO_CLIPBOARD
                      );
                    }}
                  >
                    {`Annotation Access Token: ${annotationsAccessToken}`}
                    <CopyToClipboardButton text={annotationsAccessToken} />
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>3</ListItemIcon>
                <ListItemText>
                  <Typography>{CUSTOM_MESSAGES.SETTINGS_LINE1}</Typography>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>4</ListItemIcon>
                <ListItemText>
                  <Typography>{CUSTOM_MESSAGES.SETTINGS_LINE2}</Typography>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>5</ListItemIcon>
                <ListItemText>
                  <Typography>
                    {CUSTOM_MESSAGES.SETTINGS_LINE3 + ` ${orgName}`}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>6</ListItemIcon>
                <ListItemText>
                  <Typography>{CUSTOM_MESSAGES.SETTINGS_LINE4}</Typography>
                </ListItemText>
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>7</ListItemIcon>
                <ListItemText>
                  <Typography>{CUSTOM_MESSAGES.SETTINGS_LINE5}</Typography>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </div>
      {renderBackupRestoreModal()}
      {renderBackupInputModal()}
      {renderInfoModal()}
      {renderOverlay()}
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserDetails,
      getAnnotationsAccessToken,
      getBackupList,
      createBackup,
      restoreBackup,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(Notepad));
